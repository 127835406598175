<template>

  <Modal
    v-if="!showSuccessModal"
    title="Add payment method"
    :isSmall="true"
    @close="$emit('close')"
  >
    <AddCard
      ref="cardRef"
      @isPending="isPending = true"
      @complete="showSuccessModal = true, newCardId = $event"
    />
    <template v-if="!isPending" v-slot:footer>
      <button class="button is-black" @click="cardRef.handleNewCardSubmit()">
        Add Card
      </button>
      <button class="button" @click="$emit('close')">
        Cancel
      </button>
    </template>
  </Modal>

  <Modal
    v-if="showSuccessModal"
    title="Card added"
    :isSmall="true"
    @close="$emit('close')"
  >
    <p>
      You have successfully added a new payment&nbsp;method.
    </p>
    <template v-slot:footer>
      <button class="button is-black" @click="$emit('complete', newCardId)">
        Continue
      </button>
    </template>
  </Modal> 
</template>

<script>
import { ref } from 'vue'
import Modal from '@/components/Modals/Modal.vue'
import AddCard from '@/components/Payments/AddCard.vue'

export default {
  emits: ['close', 'complete'],
  components: { Modal, AddCard },
  setup() {
    const cardRef = ref(null)
    const isPending = ref(false)
    const newCardId = ref(null)
    const showSuccessModal = ref(false)

    return {
      cardRef,
      isPending,
      newCardId,
      showSuccessModal,
    }
  }
}
</script>