<template>
  <section class="section has-border">
    <h3 class="subtitle is-size-3">
      Payment methods
    </h3>

    <div class="list has-visible-pointer-controls has-overflow-ellipsis">
      <div
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
        class="list-item"
        @click="cardToEdit = paymentMethod"
      >
        <div class="list-item-image">
            <figure class="image is-32x32">
              <img :src="`https://raw.githubusercontent.com/thegillis/stripe-credit-card-images/master/${paymentMethod.card.brand}50.jpg`" />
            </figure>
          </div>
        <div class="list-item-content">
          <div class="list-item-title is-flex">
            ••••
            {{ paymentMethod.card.last4 }}
          </div>
          <!-- <p v-if="paymentMethod.id === stripeId.id" class="heading has-text-success">Default payment method</p> -->
        </div>
        <div class="list-item-controls">
          <Icon icon="chevron-right" />
        </div>
      </div>
    </div>

    <button class="button is-ghost pl-0" @click="showAddPaymentModal = true">
      <span>Add payment method</span>
      <Icon icon="plus" />
    </button>
  </section>

  <section v-if="invoices && invoices.length" class="section has-padding-bottom">
    <h3 class="subtitle is-size-3">
      Invoices
    </h3>

    <div class="table-container">
      <table class="table is-fullwidth">
        <thead class="has-background-light">
          <th>Amount</th>
          <th>Date</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id">
            <td>
              <p class="has-text-success">{{ formatCurrency(invoice.amount_paid * .01, 'CAD') }}</p>
              <p class="help has-text-weight-normal">for {{ invoice.metadata.campaignName }}</p>
            </td>
            <td>
              {{ formatDate(invoice.created * 1000) }}
            </td>
            <td v-if="invoice.hosted_invoice_url" class="has-text-right">
              <a
                :href="invoice.hosted_invoice_url"
                target="_blank"
                class="button is-info"
              >
                Download invoice
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <AddPaymentModal
    v-if="showAddPaymentModal"
    @close="showAddPaymentModal = false"
    @complete="showAddPaymentModal = false"
  />
</template>

<script>
import { ref } from 'vue'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import Icon from '@/components/Icon.vue'
import AddPaymentModal from '@/components/Modals/AddPaymentModal'
import filters from '@/utils/filters'

export default {
  components: { Icon, AddPaymentModal },
  setup() {
    const { user } = getUser()
    const { documents: paymentMethods } = getCollection('stripe_customers', null, user.value.uid, 'payment_methods')
    const { documents: invoices } = getCollection('paidInvoices', ['customer', '==', user.value.uid])

    console.log('invoices', invoices)

    const showAddPaymentModal = ref(false)

    return {
      paymentMethods,
      showAddPaymentModal,
      invoices,
      ...filters
    }
  }
}
</script>